import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

function initSliders() {
  const mainOfferSlider = new MyTinySlider({
    sliderContainer: '[data-main-offer-slider-container]',
    sliderList: '[data-main-offer-slider]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    itemsCount: 1,
    slideBy: 1,
    nav: false,
    speed: 700,
    controls: false,
    loop: false,
    swipeAngle: false,
    rewind: false,
    animateIn: 'fade-in',
    animateOut: 'fade-out',
    mode: 'gallery',
  });

  const aboutUsSlider = new MyTinySlider({
    sliderContainer: '[data-about-slider-container]',
    sliderList: '[data-about-slider]',
    items: 1,
    slideBy: 1,
    autoplay: true,
    autoplayButtonOutput: false,
    nav: true,
    navPosition: 'bottom',
    speed: 700,
    controls: false,
    loop: true,
    swipeAngle: true,
    rewind: false,
    mode: 'gallery',
  });

  const videoReviewSlider = new MyTinySlider({
    sliderContainer: '[data-video-review-slider-container]',
    sliderList: '[data-video-review-slider]',
    slideBy: 1,
    items: 3,
    nav: false,
    speed: 700,
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    controls: false,
    loop: false,
    rewind: false,
    gutter: 24,
    responsive: {
      0: {
        fixedWidth: 216,
      },
      768: {
        fixedWidth: false,
      },
      1280: {
        items: 3,
      },
    },
  });

  const historySlider = new MyTinySlider({
    sliderContainer: '[data-history-slider-container]',
    sliderList: '[data-history-slider]',
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    slideBy: 1,
    items: 1,
    nav: true,
    gutter: 10,
    speed: 700,
    controls: false,
    loop: false,
    rewind: false,
    mode: 'gallery',
  });

  const advantagesSlider = new MyTinySlider({
    sliderContainer: '[data-advantages-slider-container]',
    sliderList: '[data-advantages-slider]',
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    slideBy: 1,
    nav: false,
    gutter: 20,
    speed: 700,
    controls: false,
    loop: false,
    rewind: false,
    responsive: {
      0: {
        items: 1.2,
        fixedWidth: false,
      },
      768: {
        items: 1.3,
        fixedWidth: false,
      },
      1280: {
        items: 1,
        fixedWidth: false,
      },
    },
  });

  const interestsAgentSlider = new MyTinySlider({
    sliderContainer: '[data-agent-interests-slider-container]',
    sliderList: '[data-agent-interests-slider]',
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    slideBy: 1,
    nav: false,
    speed: 700,
    controls: false,
    loop: true,
    rewind: false,
    responsive: {
      0: {
        items: 1.3,
        fixedWidth: false,
        gutter: 10,
      },
      768: {
        items: 2.3,
        fixedWidth: false,
        gutter: 20,
      },
      1280: {
        items: 3,
        gutter: 24,
      },
    },
  });

  const interestsLegalSlider = new MyTinySlider({
    sliderContainer: '[data-legal-interests-slider-container]',
    sliderList: '[data-legal-interests-slider]',
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    slideBy: 1,
    nav: false,
    speed: 700,
    controls: false,
    loop: true,
    rewind: false,
    responsive: {
      0: {
        items: 1.3,
        fixedWidth: false,
        gutter: 10,
      },
      768: {
        items: 2.3,
        fixedWidth: false,
        gutter: 20,
      },
      1280: {
        items: 3,
        gutter: 24,
      },
    },
  });

  const reviewsSlider = new MyTinySlider({
    sliderContainer: '[data-reviews-slider-container]',
    sliderList: '[data-reviews-slider]',
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    slideBy: 1,
    nav: false,
    speed: 700,
    loop: false,
    controls: false,
    responsive: {
      0: {
        items: 1.1,
        fixedWidth: false,
        gutter: 10,
      },
      768: {
        items: 1.2,
        gutter: 20,
      },
      1280: {
        items: 1,
      },
    },
  });

  const teamSlider = new MyTinySlider({
    sliderContainer: '[data-team-slider-container]',
    sliderList: '[data-team-slider]',
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    slideBy: 1,
    nav: false,
    speed: 700,
    controls: false,
    loop: true,
    rewind: false,
    responsive: {
      0: {
        items: 2.5,
        fixedWidth: false,
        gutter: 14,
      },
      768: {
        items: 2.7,
        fixedWidth: false,
        gutter: 20,
      },
      1280: {
        items: 4,
        fixedWidth: false,
        gutter: 25,
      },
    },
  });
}

document.addEventListener('DOMContentLoaded', initSliders);
document.addEventListener('DOMContentMutated', initSliders);

class MyTinySlider {
  constructor(options) {
    this.options = {
      sliderContainer: '',
      sliderList: '',
      itemsCount: 1,
      slideIndex: '[data-slider-index]',
      slideCount: '[data-slider-count]',
      arrowLeft: '[data-slider-prev]',
      arrowRight: '[data-slider-next]',
      sliderInitialization: 'data-slider-initialization',
      slider: null,
      disableTabletDown: false,
      tabletWidth: 1280,
      mode: 'carousel',
      animateIn: 'fade-in',
      animateOut: 'fade-out',
      hideShowArrows: false,
      autoHeight: false,
      edgePadding: 0,
      gutter: 0,
      autoWidth: false,
      fixedWidth: false,
      verticalTable: false,
    };
    this.options = Object.assign({}, this.options, options);

    this.init();
  }

  init() {
    const sliderContainers = document.querySelectorAll(this.options.sliderContainer);
    if (sliderContainers) {
      sliderContainers.forEach((sliderContainer) => {
        if (sliderContainer.dataset.sliderInitialization) {
          return true;
        }
        sliderContainer.dataset.sliderInitialization = '1';

        const sliderList = sliderContainer.querySelector(this.options.sliderList);

        if (sliderList) {
          this.initSlider(sliderContainer, sliderList);
        }

        return true;
      });
    }
  }

  initSlider(sliderContainer, sliderList) {
    const options = {
      container: sliderList,
      controls: false,
      nav: this.options.nav,
      items: this.options.itemsCount,
      speed: this.options.speed,
      autoplay: false,
      autoplayButtonOutput: false,
      mode: this.options.mode,
      animateIn: this.options.animateIn,
      animateOut: this.options.animateOut,
      autoHeight: this.options.autoHeight,
      edgePadding: this.options.edgePadding,
      gutter: this.options.gutter,
      autoWidth: this.options.autoWidth,
      fixedWidth: this.options.fixedWidth,
      responsive: {},
    };

    /* this.setParentHeight(sliderContainer); */
    options.loop = true;
    if (this.options.loop === false) {
      options.loop = false;
    }

    if (this.options.responsive) {
      options.responsive = Object.assign(options.responsive, this.options.responsive);
    }

    const largePoint = 1268;
    const mediumPoint = 768;
    if (this.options.verticalTable && window.innerWidth >= mediumPoint && window.innerWidth < largePoint) {
      options.axis = 'vertical';
    }

    if (this.options.navContainer) {
      const navContainer = document.querySelector(this.options.navContainer);
      if (navContainer) {
        options.navContainer = navContainer;
        options.navAsThumbnails = true;
      }
    }
    const slider = tns(options);

    sliderContainer.setAttribute(this.options.sliderInitialization, 'true');
    this.bindArrows(sliderContainer, slider);
    this.initSliderCounter(sliderContainer, slider);
    this.slider = slider;
  }

  initSliderCounter(sliderContainer, slider) {
    if (slider && this.options.slideIndex && this.options.slideCount) {
      const slidesIndexText = sliderContainer.querySelector(this.options.slideIndex);
      const slidesCountText = sliderContainer.querySelector(this.options.slideCount);
      let info = slider.getInfo();
      let slidesIndex = info.displayIndex;
      let slidesCount = info.slideCount;

      if (slidesIndexText && slidesCountText) {
        slidesIndexText.innerHTML = slidesIndex < 10 ? `0${slidesIndex}` : slidesIndex;
        slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;

        slider.events.on('transitionStart', () => {
          info = slider.getInfo();
          slidesIndex = info.displayIndex;
          slidesCount = info.slideCount;
          slidesIndexText.innerHTML = slidesIndex < 10 ? `0${slidesIndex}` : slidesIndex;
          slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;
        });
      }
    }
  }

  bindArrows(sliderContainer, slider) {

    const prevArrow = sliderContainer.querySelector(this.options.arrowLeft);

    if (prevArrow) {
      prevArrow.addEventListener('click', () => {
        slider.goTo('prev');
      });
    }


    const nextArrow = sliderContainer.querySelector(this.options.arrowRight);

    if (nextArrow) {
      nextArrow.addEventListener('click', () => {
        slider.goTo('next');
      });
    }

    if (this.options.hideShowArrows && prevArrow && nextArrow) {
      slider.events.on('transitionStart', () => {
        const info = slider.getInfo();
        const currentIndex = info.displayIndex;
        const maxIndex = info.slideCount - (info.items - 1);

        prevArrow.classList.remove('_hide');
        nextArrow.classList.remove('_hide');
        if (currentIndex === 1) {
          prevArrow.classList.add('_hide');
        } else if (currentIndex === maxIndex) {
          nextArrow.classList.add('_hide');
        }
      });
    }
  }
}