import IMask from 'imask';

const initPhoneField = () => {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = 'true';

    const mask = IMask(input, {
      mask: '+{7} (000) 000-00-00',
    });
  });
};

document.addEventListener('DOMContentLoaded', initPhoneField);
document.addEventListener('DOMContentMutated', initPhoneField);