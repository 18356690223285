const tabs = document.querySelectorAll('[data-main-tab]');
const mutableLink = document.querySelector('[data-main-mutable-link]');

tabs.forEach((item) => {
  item.addEventListener('click', () => {
    const value = item.getAttribute('title');
    const mutableText = mutableLink.querySelector('[data-main-mutable-value]');

    mutableText.innerHTML = value;
    mutableLink.setAttribute('href', item.dataset.mainTab);
  });
});