function openModal() {
  const links = document.querySelectorAll('[data-modal-item]');

  links.forEach((link) => {
    const item = link.closest('[data-worker-item]');
    const modalWindow = item.querySelector('[data-modal-window]');
    const modalCloser = item.querySelector('[data-modal-closer]');

    link.addEventListener('click', () => {
      const openWindows = document.querySelectorAll('[data-modal-window]._show');
      openWindows.forEach((openWindow) => {
        openWindow.classList.remove('_show');
      });
      if (!modalWindow.classList.contains('_show')) {
        modalWindow.classList.add('_show');
      }
    });
    modalCloser.addEventListener('click', () => {
      modalWindow.classList.remove('_show');
    });
  });
}

document.addEventListener('DOMContentLoaded', openModal);