function initHamburger() {
  const hamburger = document.querySelector('[data-hamburger]');
  const menu = document.querySelector('[data-header-bottom]');
  if (hamburger && menu) {
    hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      hamburger.classList.toggle('_open');
      menu.classList.toggle('_open');
    });
  }
}

function initMenuLinks() {
  const rootStyles = getComputedStyle(document.documentElement);
  const mediumWidth = rootStyles.getPropertyValue('--medium-inner-width') || 1279;
  document.querySelectorAll('[data-has-child-link]').forEach((link) => {
    const parent = link.parentNode;
    link.addEventListener('click', (e) => {
      if (window.innerWidth <= mediumWidth) {
        e.preventDefault();
        parent.classList.toggle('_open');
      }
    });
  });
}

function initMenu() {
  initHamburger();
  initMenuLinks();
}

document.addEventListener('DOMContentLoaded', initMenu);