function initStickyHeader() {
  const header = document.querySelector('[data-fixed-header]');
  if (header) {
    document.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        header.classList.add('_sticky');
      } else {
        header.classList.remove('_sticky');
      }
    });
  }
}

function initHeader() {
  initStickyHeader();

  const event = new Event('scroll');
  document.dispatchEvent(event);
}

document.addEventListener('DOMContentLoaded', initHeader);