require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goal');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');

require('./field/phone');
require('./field/floating');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('../components/header/header');
require('../vue/app');

require('../fonts/Effra/stylesheet.css');
require('./menu/menu');
require('./sliders/slider');
// require('./pagination/auto-pagination');
require('fslightbox');
require('./worker-modal');
require('fslightbox');
require('./lightbox/lightbox');
require('./publications/change-show-all-link');